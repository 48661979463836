'use strict'

import _, {cloneDeep, debounce, findIndex, groupBy} from 'lodash'
import download from "downloadjs";

import {Order, OrderStatus} from "shared-model";
import {
    OrdersUpdateRequest,
    ManagedOrder,
    OrderIds,
    OrdersState,
    RefundPayload,
    UpdateMessagePayload, OrdersFilterStatus,
} from "./orders-store.types";
import {BatchChangeStatusResponse, LoadOrdersRequest} from "@/api/api-model";
import {OrderApi} from "@/api";

const state = () => ({
    orders: [],
    openStatuses: [
        OrderStatus.CREATED,
        OrderStatus.PAID,
        OrderStatus.PLACED,
        OrderStatus.ORDERED,
        OrderStatus.TO_PREPARE,
        OrderStatus.TO_DISPATCH,
        OrderStatus.GIVEN_TO_CARRIER,
        OrderStatus.AVAILABLE,
        OrderStatus.ERROR,
        OrderStatus.CANCELLED // ???
    ],
    error: null,
    loading: false
})

const getters = {

    getOrders: (state: OrdersState) => {
        return state.orders
    },

    getOrderById: (state: OrdersState) => (id: string) => {
        const index = state.orders.findIndex(o => o.orderId === id);
        return index !== -1 ? state.orders[index] : null
    },

    getOrdersByStatus: (state: OrdersState) => {
        return groupBy(state.orders, 'status')
    },
    getStatusName: () => (status: OrdersFilterStatus) => {
        switch (status) {
            case OrderStatus.AVAILABLE:
                return "A dispo"
            case OrderStatus.CREATED:
                return "Crée"
            case OrderStatus.PAID:
                return "Payée mais non commandées"
            case OrderStatus.PLACED:
                return "A commander"
            case OrderStatus.ORDERED:
                return "En attente de livraison"
            case OrderStatus.TO_PREPARE:
                return "A préparer"
            case OrderStatus.TO_DISPATCH:
                return "A expédier"
            case OrderStatus.GIVEN_TO_CARRIER:
                return "Remis au transporteur"
            case OrderStatus.DONE:
                return "Remis au client"
            case OrderStatus.ERROR:
                return "En erreur"
             case OrderStatus.CANCELLED:
                 return "Annulée"
            case "ALL":
                return "Toutes les commandes"
            default:
                return status
        }
    },

    getFilterStatuses: (state: OrdersState, getters: any) => {
        return [
            "ALL",
            OrderStatus.PLACED,
            OrderStatus.ORDERED,
            OrderStatus.TO_PREPARE,
            OrderStatus.TO_DISPATCH,
            OrderStatus.AVAILABLE,
            OrderStatus.DONE,
            OrderStatus.ERROR
        ].map(status => {
            return {
                key: status,
                label: getters.getStatusName(status)
            }
        })
    }
}

const actions = {

    getOpenOrders({commit, state} : {commit: any, state: OrdersState}) {
        commit("setLoading", true)
        return OrderApi.loadOrders({ statuses: state.openStatuses })
            .then((response: Order[]) => {
                commit("setOrders", response)
                return response
            }).catch(() => {
                commit("setError", "Erreur de chargement des commandes")
            }).finally(() => commit("setLoading", false))
    },

    getOrders({commit} : {commit: any}, params: LoadOrdersRequest) {

        commit("setLoading", true)
        return OrderApi.loadOrders(params)
            .then((response: Order[]) => {
                commit("setOrders", response)
                return response
            }).catch(() => {
                commit("setError", "Erreur de chargement des commandes")
            }).finally(() => commit("setLoading", false))
    },

    async searchForTerminatedOrders({getters, state, commit}:  {commit: any, state: OrdersState, getters: any}, search: string) {
        try {
            const existingIndex = state.orders.findIndex(o => o.orderId.indexOf(search) !== -1)

            if (existingIndex === -1) {
                const response = await OrderApi.searchTerminatedOrders([OrderStatus.DONE, OrderStatus.CANCELLED], search)
                if (response.length === 1) commit("setOrder", response[0])
                return response
            } else return state.orders[existingIndex]

        } catch (e) {
            throw "Erreur lors de la recherche"
        }
    },

    async getOrderById({commit} : {commit: any}, ids: OrderIds) {

        try {

            const response = await OrderApi.loadOrderById(ids)
            commit("setOrder", response)
            return response

        } catch (e) {
          throw "Erreur lors du chargement"
        } finally {
            commit("setLoading", false)
        }
    },

    createWinProOrders({commit}: {commit: any}, orders: Order[]) {
        commit("setLoading", true)
        return OrderApi.createWinProOrders(orders).then((responses: Order[]) => {

            responses.forEach((r: Order) => commit('setOrder', r))
            return responses

        }).catch((status: number) => {
            switch (status) {
                case 404:
                    throw "Mauvais status"
                default:
                    throw "Erreur technique"
            }
        }).finally(() => commit("setLoading", false))
    },

    async executeBatchAction({commit}: {commit: any}, request: OrdersUpdateRequest) {

        if (!request.action || request.payload.length === 0) return;

        try {
            commit("setLoading", true)
            const response =  await OrderApi.changeStatusBatch(request)
            commit("changeOrdersStatus", response)
            return response

        } catch (status) {
            switch (status) {
                case 404:
                    throw "Mauvais status"
                default:
                    throw "Erreur technique. Veuillez réessayer."
            }
        } finally {
            commit("setLoading", false)
        }
    },
    changeStatus({commit}: {commit: any}, request: OrdersUpdateRequest) {

        commit("setLoading", true)

        return OrderApi.changeStatusBatch(request)
            .then((batch: BatchChangeStatusResponse[]) => {
                const response = batch[0]
                if (!response.error) commit("setOrder", response.order)
                return response
            }).catch(() => {
                throw "Erreur technique. Veuillez réessayer."
            }).finally(() => commit("setLoading", false))
    },

    async addWinProOrder({commit}: {commit: any}, order: Order) {

        try {
            const response = await OrderApi.addWinProOrder(order)
            commit("setOrder", response)

        } catch (e) {
            if (e === 400) throw "Utilisateur inconnu"
            throw "Commande non sauvegardée"
        }

    },

    async printTransportLabel(_: any, ids: OrderIds) {
        try {
            const response = await OrderApi.printTransportLabel(ids)
            const content = response.headers['content-type'];
            download(response.data, `${ids.orderId}_transport_label.pdf`, content)

        } catch (e) {
            throw "Erreur lors de l'impression"
        }
    },
    async printTransportLabelBatch(_: any, orderIds: OrderIds[]) {
        try {
            const response = await OrderApi.printTransportLabelBatch(orderIds)
            const content = response.headers['content-type'];
            download(response.data, `orders_transport_label.pdf`, content)

        } catch (e) {
            throw "Erreur lors de l'impression"
        }
    },
    async refundOrder({commit}: {commit: any}, payload: RefundPayload) {

        try {
            const response = await OrderApi.refundOrder(payload)
            commit("setOrder", response)
            return response

        } catch (e) {
            throw "Erreur lors du remboursement"
        }
    },

    async deleteOrder({commit}: {commit: any}, order: Order) {

        try {
            await OrderApi.deleteOrder(order)
            commit("removeOrder", order)

        } catch (e) {
            if (e === 400) throw "Utilisateur inconnu"
            throw "Commande non sauvegardée"
        }
    },
    saveMessage({commit}: {commit: any}, payload: UpdateMessagePayload) {
        return OrderApi.saveMessage(payload).then((newOrder: Order) => {
            commit("setOrder", newOrder)

        }).catch((error: any) => {
            console.log("error in subscribeToNewOrder: " + JSON.stringify(error))
        }).finally(() => {})
    }
}

const mutations = {

    setOrders(state: OrdersState, orders: ManagedOrder[]) {
        state.orders = orders
    },

    updateOrders(state: OrdersState, orders: ManagedOrder[]) {
        orders.forEach(o => {
            this.setOrder(state, o)
        })
    },

    setError(state: OrdersState, error: string) {
        state.error = error
    },

    setLoading(state: OrdersState, loading: boolean) {
        if (loading) state.error = undefined
        state.loading = loading
    },
    setOrder(state: OrdersState, order: ManagedOrder) {

        const orders = state.orders;
        const index = orders.findIndex(o => o.orderId === order.orderId)
        if (index === -1) {
            if (!order.isInError) orders.push(order)

        } else {
            if (order.isInError) {
                orders[index] = Object.assign({
                    isInError: true,
                    errorCode: order.errorCode,
                    errorMessage: order.errorMessage
                }, orders[index])

            } else orders[index] = Object.assign({}, order)
        }

        state.orders = cloneDeep(orders)

    },
    removeOrder(state: OrdersState, order: Order) {
        const index = state.orders.findIndex(o => o.orderId === order.orderId)
        if (index !== -1) {
            state.orders.splice(index, 1)
        }
    },

    changeOrdersStatus(state: OrdersState, orders: BatchChangeStatusResponse[]) {

        orders.forEach(updatedBatchItem => {

            console.log("looping")
            const order = updatedBatchItem.order as ManagedOrder

            const stateOrderIndex = findIndex(state.orders, {orderId: order.orderId});
            const stateOrder = stateOrderIndex !== -1 ? state.orders[stateOrderIndex] : order

            if (updatedBatchItem.error) {
                console.log("order.errorCode : " + JSON.stringify(order.errorCode))
                console.log("order.errorMessage : " + JSON.stringify(updatedBatchItem.errorMessage))
                console.log("order.status : " + JSON.stringify(order.status))
                stateOrder.isInError = true
                stateOrder.errorCode = order.errorCode//FIXME should only use errorCode
                stateOrder.errorMessage = updatedBatchItem.errorMessage
                stateOrder.status = order.status
            } else {
                delete stateOrder.isInError
                delete stateOrder.errorCode
                delete stateOrder.errorMessage
                stateOrder.status = order.status
            }

            if (stateOrderIndex !== -1)
                state.orders.splice(stateOrderIndex, 0, stateOrder)
            else
                state.orders.splice(state.orders.length, 0, stateOrder)

        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
