import axios, { AxiosResponse} from 'axios'
import {Order, OrderStatus} from 'shared-model'
import {LoadOrdersRequest} from "./api-model";
import {OrderIds, OrdersUpdateRequest, RefundPayload, UpdateMessagePayload} from "@/store/modules/orders-store.types";

export default {

    async createOrder(order: Order): Promise<Order> {
        const response = await axios.post("/orders", order)
        if (response.status > 300) throw "Error creating order"
        else return response.data
    },

    async loadOrders(payload: LoadOrdersRequest): Promise<Array<Order>> {

        const params = new URLSearchParams();
        if (payload.statuses) {
            for (let i = 0; i < payload.statuses.length; i++) {
                params.append("statuses", payload.statuses[i]);
            }
        }

        if (payload.search) {
            params.append("search", payload.search);
        }

        if (payload.after) {
            params.append("after", payload.after);
        }

        return new Promise((success, die) => {
            axios.get(`/orders`, { params }).then(response => {
                if (response.status === 200) {
                    success(response.data)
                }
                die()
            }).catch(die)
        })
    },
    loadOrderById({userId, orderId}: OrderIds): Promise<Order> {
        return new Promise((success, die) => {
            axios.get(`/orders/${orderId}/${userId}`).then(response => {
                if (response.status === 200) {
                    success(response.data)
                }
                die()
            }).catch(die)
        })
    },
    async searchTerminatedOrders(statuses: OrderStatus[], search: string) {

        const params = new URLSearchParams();
        if (statuses) {
            for (let i = 0; i < statuses.length; i++) {
                params.append("statuses", statuses[i]);
            }
        }

        params.append("search", search);
        const response = await axios.get(`/orders`, { params })
        return response.data
    },
    async addWinProOrder(order: Order) {

        try {

            const response = await axios.put(`/orders`, order)
            return response.data

        } catch (e: any) {
            if (e.message.indexOf("400")) throw 400
            throw 500
        }

    },
    async deleteOrder(order: Order) {

        try {

            const response = await axios.delete(`/orders/${order.orderId}/${order.userId}`)
            return response.data

        } catch (e: any) {
            if (e.message.indexOf("400")) throw 400
            throw 500
        }

    },
    async saveMessage(payload: UpdateMessagePayload) {
        const response = await axios.put(`/orders/${payload.orderId}/${payload.userId}/message`, payload.message)
        return response.data
    },

    createWinProOrders(orders: Order[]): Promise<Array<Order>> {

        const requests = orders.map(o => axios.put("/orders", o))

        return axios.all(requests).then(axios.spread((...responses: Array<AxiosResponse>) => {

            return responses.map(r => r.data)

        })).catch(e => {
            if (e.message.indexOf("400")) throw 400
            else if (e.message.indexOf("404")) throw 404
            else throw 500
        })
    },

    async refundOrder(payload: RefundPayload): Promise<Order> {
        const response = await axios.post(`/orders/${payload.orderId}/${payload.userId}/refund`, {
            amount: payload.amountToRefund
        })
        return response.data
    },

    async printTransportLabel(ids: OrderIds) {
        return await axios.get(`/orders/${ids.orderId}/${ids.userId}/transport_label`, {responseType: 'blob'})
    },

    async printTransportLabelBatch(orderIds: OrderIds[]) {
        return await axios.post(`/orders/batch/transport_label`, orderIds, {responseType: 'blob'})
    },

    async changeStatusBatch(request: OrdersUpdateRequest) {

        try {
            const response = await axios.post(`/orders/batch`, request, {
                headers: {
                    'Content-type': 'application/json'
                }
            })
            return response.data

        } catch (e) {

            console.log("e : " + JSON.stringify(e))
            throw 500
        }
    },
}
